<template>
<div id="page-content-wrapper" :style="cssProps">
 <div class="grid-stack" id="atea-widget-container" style="height: 100vh;">
    <DashboardWidget
      v-for="widget in widgets.filter(w => w.enabled)"
      :key="widget.id"
      :id="widget.id"
      :wid="widget.id"
      :wstyle="widget.wstyle"
      :wgrid="widget.gridcount"
      :aicon="widget.aicon"
      :title="widget.title"
      :gs-x="widget.x"
      :gs-y="widget.y"
      :gs-w="widget.w"
      :gs-h="widget.h"
      :gs-id="widget.id"
      :panelClass="widget.class"
      :initDone="initDone"
    >
      <component v-bind:is="widget.component"></component>
    </DashboardWidget>
  </div>
  <DashboardFooter
    ref="dashboardFooter"
  />
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import 'gridstack/dist/gridstack.min.css'
import { GridStack } from 'gridstack'
// import 'gridstack/dist/h5/gridstack-dd-native'
import GraphqlService from '../service/GraphqlService'
import WebsocketService from '../service/WebsocketService'
import DashboardWidget from '@/modules/shared/components/DashboardWidget'
import DashboardFooter from '@/modules/shared/components/DashboardFooter'
import CallsWaitingWidget from '@/modules/shared/components/widgets/CallsWaitingWidget'
import LongestWaitingWidget from '@/modules/shared/components/widgets/LongestWaitingWidget'
import QueuesWidget from '@/modules/shared/components/widgets/QueuesWidget'
import EmailQueuesWidget from '@/modules/shared/components/widgets/EmailQueuesWidget'
import ChatQueuesWidget from '@/modules/shared/components/widgets/ChatQueuesWidget'
import AgentsWidget from '@/modules/shared/components/widgets/AgentsWidget'
import AgentListWidget from '@/modules/shared/components/widgets/AgentListWidget'
import LogoWidget from '@/modules/shared/components/widgets/LogoWidget'
import GraphWidget from '@/modules/shared/components/widgets/GraphWidget'
import WaitGraphWidget from '@/modules/shared/components/widgets/WaitGraphWidget'
import TotalCallsWidget from '@/modules/shared/components/widgets/TotalCallsWidget'
import TotalAbandonedPctWidget from '@/modules/shared/components/widgets/TotalAbandonedPctWidget'
import AvgWaitWidget from '@/modules/shared/components/widgets/AvgWaitWidget'
import GosWidget from '@/modules/shared/components/widgets/GosWidget'
import MarqueeWidget from '@/modules/shared/components/widgets/MarqueeWidget'
export default {
  name: 'Dashboard',
  data () {
    return {
      initDone: false,
      grid: null,
      dashId: 0,
      callsWaiting: 3,
      longestWaiting: '00:31',
      poller: null,
      tickerworker: null,
      widgets: [
        {
          id: 'gs-logo',
          title: 'Atea WxCC',
          x: '0',
          y: '0',
          w: '1',
          h: '3',
          class: '',
          component: LogoWidget,
          enabled: true
        },
        {
          id: 'gs-queue',
          title: 'Calls Waiting',
          x: '1',
          y: '0',
          w: '1',
          h: '3',
          class: 'atea-center',
          wstyle: 'font-size: 6em;',
          component: CallsWaitingWidget,
          enabled: false
        },
        {
          id: 'gs-oldest',
          title: 'Longest Waiting',
          x: '2',
          y: '0',
          w: '2',
          h: '3',
          class: 'atea-center',
          wstyle: 'font-size: 6em;',
          component: LongestWaitingWidget,
          enabled: false
        },
        {
          id: 'gs-totalcalls',
          title: 'Total Calls Handled',
          x: '4',
          y: '0',
          w: '2',
          h: '3',
          class: 'atea-center',
          wstyle: 'font-size: 6em;',
          component: TotalCallsWidget,
          enabled: false
        },
        {
          id: 'gs-abandondcalls',
          title: 'Total Calls Abandoned',
          x: '6',
          y: '0',
          w: '2',
          h: '3',
          class: 'atea-center',
          wstyle: 'font-size: 6em;',
          component: TotalAbandonedPctWidget,
          enabled: false
        },
        {
          id: 'gs-avgwait',
          title: 'Avg WaitTime',
          x: '8',
          y: '0',
          w: '2',
          h: '3',
          class: 'atea-center',
          wstyle: 'font-size: 6em;',
          component: AvgWaitWidget,
          enabled: false
        },
        {
          id: 'gs-gos',
          title: 'Grade of Service',
          x: '10',
          y: '0',
          w: '2',
          h: '3',
          class: 'atea-center',
          wstyle: 'font-size: 6em;',
          component: GosWidget,
          enabled: false
        },
        {
          id: 'gs-queues',
          title: 'Voice Queues',
          x: '0',
          y: '3',
          w: '6',
          h: '8',
          class: '',
          wstyle: 'font-size: 1.2em;',
          component: QueuesWidget,
          enabled: false
        },
        {
          id: 'gs-agentlist',
          title: 'Agents',
          x: '6',
          y: '3',
          w: '6',
          h: '8',
          class: '',
          wstyle: 'font-size: 1em;',
          aicon: '--agent-list-icon-width',
          component: AgentListWidget,
          enabled: false
        },
        {
          id: 'gs-emailqueues',
          title: 'Email Queues',
          x: '0',
          y: '11',
          w: '6',
          h: '4',
          class: '',
          wstyle: 'font-size: 1.2em;',
          component: EmailQueuesWidget,
          enabled: false
        },
        {
          id: 'gs-chatqueues',
          title: 'Chat Queues',
          x: '0',
          y: '15',
          w: '6',
          h: '4',
          class: '',
          wstyle: 'font-size: 1.2em;',
          component: ChatQueuesWidget,
          enabled: false
        },
        {
          id: 'gs-agents',
          title: 'Agents',
          x: '6',
          y: '11',
          w: '6',
          h: '8',
          class: '',
          wstyle: 'font-size: 1em;',
          gridcount: '--num-agent-grid',
          aicon: '--agent-grid-icon-width',
          component: AgentsWidget,
          enabled: false
        },
        {
          id: 'gs-graph',
          title: 'Calls Today',
          x: '0',
          y: '19',
          w: '6',
          h: '3',
          class: 'atea_full_height',
          wstyle: 'font-size: 1em;',
          component: GraphWidget,
          enabled: false
        },
        {
          id: 'gs-waitgraph',
          title: 'Wait Times Today',
          x: '6',
          y: '19',
          w: '6',
          h: '3',
          class: 'atea_full_height',
          wstyle: 'font-size: 1em;',
          component: WaitGraphWidget,
          enabled: false
        },
        {
          id: 'gs-marquee',
          title: 'Message',
          x: '0',
          y: '22',
          w: '12',
          h: '2',
          class: 'atea_full_height',
          wstyle: 'font-size: 4em;',
          component: MarqueeWidget,
          enabled: false
        }
      ],
      availableWidgets: [],
      dummyA: ['Modesta Rakestraw', 'Michael Gracia', 'Clinton Brasher', 'Daniel Staten', 'Wilbur Smiley', 'Martha Young', 'Lucien Young', 'Carolyn Foster', 'Helen Coffman', 'Anna Hodge', 'Dennis Sykes', 'Steven Liles', 'Lenora Knight', 'Kimberley Engel', 'Richard Sanchez', 'Sandra Brooks', 'Robert Garcia', 'John McClendon', 'Pauline Robinson', 'Kristy Parsley', 'Lorenzo Hoffman', 'Elizabeth Latson', 'Francisco Healy', 'Rozanne Holeman', 'David Matherne', 'Victor Burwell', 'Travis Christian', 'Damon Riley', 'Violet Cruz', 'Anita Winegar', 'Neil Wendt', 'Greg Littlejohn', 'Jason Unrein', 'Eugene James', 'Justin Harr', 'Ralph Hamilton', 'George Holmes', 'Thomas Richardson', 'Eric Wales', 'Bernard Neal', 'Helen Galindo', 'Louise Gambino', 'Juan Hice', 'Heather Tran', 'Frances Devore', 'Jessica Haines', 'Harvey Ford', 'Helen Wilson', 'Michael Butler', 'Troy Lynch'],
      dummyQ: ['Aust Sales', 'Aust Support', 'Japan Sales', 'China Sales', 'China Support', 'Japan Support', 'NZ Sales', 'Aust Sales', 'US Sales', 'US Support', 'NZ Support', 'Global HR', 'Finance', 'Reservations', 'VIP Res', 'Legal', '24HR Hotline', 'NZ HR', 'Reception', 'Engineering', 'Marketing', 'Escalation', 'FreeCall', 'Asia Sales', 'Asia Support', 'HK Sales', 'HK Support', 'India Sales', 'India Support']
    }
  },
  async mounted () {
    console.log('Dashboard - mounted')
    this.dashId = this.$route.params.id
    this.$store.commit('auth/setShowNav', false)
    this.$store.commit('wxcc/setDashId', this.dashId)
    if (this.$route.query.at) this.$store.commit('wxcc/setPublicAccessToken', this.$route.query.at)
    const db = await GraphqlService.getDashboard(this.$store, this.dashId, false)
    const allqueuegroups = await GraphqlService.getQueueGroups(this.$store, db.groupids, false)
    this.widgets = await this.widgets.map(w => {
      if (db.widgets.includes('gs-queue') && w.id === 'gs-queue') w.enabled = true
      if (db.widgets.includes('gs-oldest') && w.id === 'gs-oldest') w.enabled = true
      if (db.widgets.includes('gs-totalcalls') && w.id === 'gs-totalcalls') w.enabled = true
      if (db.widgets.includes('gs-abandondcalls') && w.id === 'gs-abandondcalls') w.enabled = true
      if (db.widgets.includes('gs-avgwait') && w.id === 'gs-avgwait') w.enabled = true
      if (db.widgets.includes('gs-gos') && w.id === 'gs-gos') w.enabled = true
      if (db.widgets.includes('gs-queues') && w.id === 'gs-queues') w.enabled = true
      if (db.widgets.includes('gs-agents') && w.id === 'gs-agents') w.enabled = true
      if (db.widgets.includes('gs-graph') && w.id === 'gs-graph') w.enabled = true
      if (db.widgets.includes('gs-waitgraph') && w.id === 'gs-waitgraph') w.enabled = true
      if (db.widgets.includes('gs-marquee') && w.id === 'gs-marquee') w.enabled = true
      if (db.widgets.includes('gs-agentlist') && w.id === 'gs-agentlist') w.enabled = true
      if (db.widgets.includes('gs-emailqueues') && w.id === 'gs-emailqueues') w.enabled = true
      if (db.widgets.includes('gs-chatqueues') && w.id === 'gs-chatqueues') w.enabled = true

      if (db.widgets.includes('gs-queues') && db.widgets.includes('gs-emailqueues') && db.widgets.includes('gs-chatqueues')) {
        if (w.id === 'gs-queues') {
          w.h = '6'
        }
        if (w.id === 'gs-emailqueues') {
          w.x = '0'
          w.y = '9'
          w.w = '6'
          w.h = '5'
        }
        if (w.id === 'gs-chatqueues') {
          w.x = '0'
          w.y = '14'
          w.w = '6'
          w.h = '5'
        }
      } else if (db.widgets.includes('gs-queues') && db.widgets.includes('gs-emailqueues') && !db.widgets.includes('gs-chatqueues')) {
        if (w.id === 'gs-queues') {
          w.h = '8'
        }
        if (w.id === 'gs-emailqueues') {
          w.x = '0'
          w.y = '11'
          w.w = '6'
          w.h = '8'
        }
      } else if (db.widgets.includes('gs-queues') && !db.widgets.includes('gs-emailqueues') && db.widgets.includes('gs-chatqueues')) {
        if (w.id === 'gs-queues') {
          w.h = '8'
        }
        if (w.id === 'gs-emailqueues') {
          w.x = '0'
          w.y = '11'
          w.w = '6'
          w.h = '8'
        }
      }

      return w
    })

    // if (db.widgets.includes('gs-queues') && db.widgets.includes('gs-emailqueues') && db.widgets.includes('gs-chatqueues')) {
    //   this.widgets = await this.widgets.map(w => {
    //     if (w.id === 'gs-queues') {
    //       w.h = '6'
    //     }
    //     if (w.id === 'gs-emailqueues') {
    //       w.x = '0'
    //       w.y = '5'
    //       w.w = '6'
    //       w.h = '5'
    //     }
    //     if (w.id === 'gs-chatqueues') {
    //       w.x = '0'
    //       w.y = '11'
    //       w.w = '6'
    //       w.h = '5'
    //     }
    //     return w
    //   })
    //   console.log('updated widgets', JSON.stringify(this.widgets))
    // } else if (db.widgets.includes('gs-queues') && db.widgets.includes('gs-emailqueues') && !db.widgets.includes('gs-chatqueues')) {
    //   this.widgets = await this.widgets.map(w => {
    //     if (w.id === 'gs-queues') {
    //       w.h = '8'
    //     }
    //     if (w.id === 'gs-emailqueues') {
    //       w.x = '0'
    //       w.y = '11'
    //       w.w = '6'
    //       w.h = '8'
    //     }
    //     return w
    //   })
    //   console.log('updated widgets', JSON.stringify(this.widgets))
    // } else if (db.widgets.includes('gs-queues') && !db.widgets.includes('gs-emailqueues') && db.widgets.includes('gs-chatqueues')) {
    //   this.widgets = await this.widgets.map(w => {
    //     if (w.id === 'gs-queues') {
    //       w.h = '8'
    //     }
    //     if (w.id === 'gs-chatqueues') {
    //       w.x = '0'
    //       w.y = '11'
    //       w.w = '6'
    //       w.h = '8'
    //     }
    //     return w
    //   })
    //   console.log('updated widgets', JSON.stringify(this.widgets))
    // }
    // this.widgets = await this.widgets.filter(w => {
    //   if (w.id === 'gs-logo') return true
    //   if (db.widgets.includes('gs-queue') && w.id === 'gs-queue') return true
    //   if (db.widgets.includes('gs-oldest') && w.id === 'gs-oldest') return true
    //   if (db.widgets.includes('gs-totalcalls') && w.id === 'gs-totalcalls') return true
    //   if (db.widgets.includes('gs-avgwait') && w.id === 'gs-avgwait') return true
    //   if (db.widgets.includes('gs-gos') && w.id === 'gs-gos') return true
    //   if (db.widgets.includes('gs-queues') && w.id === 'gs-queues') return true
    //   if (db.widgets.includes('gs-agents') && w.id === 'gs-agents') return true
    //   if (db.widgets.includes('gs-graph') && w.id === 'gs-graph') return true
    //   if (db.widgets.includes('gs-marquee') && w.id === 'gs-marquee') return true
    //   if (db.widgets.includes('gs-agentlist') && w.id === 'gs-agentlist') return true
    //   if (db.widgets.includes('gs-emailqueues') && w.id === 'gs-emailqueues') return true
    //   if (db.widgets.includes('gs-chatqueues') && w.id === 'gs-chatqueues') return true
    //   return false
    // })
    // console.log('---updated widgets', JSON.stringify(this.widgets))
    this.$store.commit('wxcc/SET_DASHBOARD', db)
    this.$store.commit('wxcc/SET_QUEUE_GROUPS', allqueuegroups)
    // this.widgets = this.widgets.filter(el => {
    //   if (db.widgets.includes(el.id) || el.id === 'gs-logo') return true
    //   return false
    // })
    // console.dir(this.widgets)
    // if (db.widgets.includes('gs-agents')) {
    //   this.widgets.push({
    //     id: 'gs-agents',
    //     title: 'Agents',
    //     x: '6',
    //     y: '3',
    //     w: '6',
    //     h: '16',
    //     class: '',
    //     wstyle: 'font-size: 1em;',
    //     gridcount: '--num-agent-grid',
    //     aicon: '--agent-grid-icon-width',
    //     component: AgentsWidget
    //   })
    // } else if (db.widgets.includes('gs-agentlist')) {
    //   this.widgets.push({
    //     id: 'gs-agentlist',
    //     title: 'Agents',
    //     x: '6',
    //     y: '3',
    //     w: '6',
    //     h: '16',
    //     class: '',
    //     wstyle: 'font-size: 1em;',
    //     aicon: '--agent-list-icon-width',
    //     component: AgentListWidget
    //   })
    // }
    // else if (db.widgets.includes('gs-graph') && !db.widgets.includes('gs-marquee')) {
    //   this.widgets.push({
    //     id: 'gs-graph',
    //     title: 'Calls Today',
    //     x: '0',
    //     y: '19',
    //     w: '12',
    //     h: '5',
    //     class: 'atea_full_height',
    //     wstyle: 'font-size: 1em;',
    //     component: GraphWidget
    //   })
    // } else if (!db.widgets.includes('gs-graph') && db.widgets.includes('gs-marquee')) {
    //   console.log('ADDING MARQUEE')
    //   this.widgets.push({
    //     id: 'gs-marquee',
    //     title: 'Message',
    //     x: '0',
    //     y: '19',
    //     w: '12',
    //     h: '5',
    //     class: 'atea_full_height',
    //     wstyle: 'font-size: 4em;',
    //     component: MarqueeWidget
    //   })
    // }
    // console.log('widgets ', this.widgets)
    this.availableWidgets = db.widgets
    this.loadGrid()
    this.grid = GridStack.init({
      row: 24,
      margin: 3,
      cellHeight: '4.14%',
      acceptWidgets: false,
      float: true,
      auto: true
    })
    // this.grid.load(this.widgets)
    // if (db.widgets.includes('gs-agents')) {
    //   this.grid.addWidget({
    //     id: 'gs-agents',
    //     title: 'Agents',
    //     x: '6',
    //     y: '3',
    //     w: '6',
    //     h: '16',
    //     class: '',
    //     wstyle: 'font-size: 1em;',
    //     gridcount: '--num-agent-grid',
    //     aicon: '--agent-grid-icon-width',
    //     component: AgentsWidget
    //   })
    // }
    // if (db.widgets.includes('gs-agentlist')) {
    //   this.grid.addWidget({
    //     id: 'gs-agentlist',
    //     title: 'Agents',
    //     x: '6',
    //     y: '3',
    //     w: '6',
    //     h: '16',
    //     class: '',
    //     wstyle: 'font-size: 1em;',
    //     aicon: '--agent-list-icon-width',
    //     component: AgentListWidget
    //   })
    // }
    // console.log('gridstack', this.grid)
    this.widgets.forEach(item => {
      if (!this.availableWidgets.includes(item.id) && item.id !== 'gs-logo') {
        console.log('removing widget', '#' + item.id)
        this.grid.removeWidget('#' + item.id, true)
      }
      if (db.logo === 'HIDE' && item.id === 'gs-logo') {
        console.log('removing widget', '#' + item.id)
        this.grid.removeWidget('#' + item.id, true)
      }
    })
    this.loadGrid()
    this.grid.on('dragstop', (event, element) => {
      this.saveGrid()
    })
    this.grid.on('resizestop', (event, element) => {
      this.saveGrid()
    })
    // this.$emit('init-done')
    this.initDone = true
    // console.dir('CHECKING GRID ELEM', this.grid)
    // this.grid.compact()
    this.updateAllStats()
    this.$store.dispatch('wxcc/INIT', false)
    console.log(`apiServer before websocket: ${this.$store.getters['wxcc/apiServer']}`)
    WebsocketService.connectWs(this.$store, this.dashId, this.updateAllStats, this.updateQueueStats, this.updateMarquee)
    this.poller = setInterval(() => {
      this.getAgents()
      this.getQueues()
      this.gethourlyQueueStats()
    }, 2 * 60 * 1000)
  },
  computed: {
    ...mapGetters({
      dbname: 'wxcc/dbname',
      storedagents: 'wxcc/agents',
      lastWsMessage: 'wxcc/lastWsMessage',
      lastStatsUpdate: 'wxcc/lastStatsUpdate',
      colorfontinfo: 'wxcc/colorfontinfo',
      colorbackgroundinfo: 'wxcc/colorbackgroundinfo',
      colorfontwarning: 'wxcc/colorfontwarning',
      colorbackgroundwarning: 'wxcc/colorbackgroundwarning',
      colorfonturgent: 'wxcc/colorfonturgent',
      colorbackgroundurgent: 'wxcc/colorbackgroundurgent',
      lwcolorfontinfo: 'wxcc/lwcolorfontinfo',
      lwcolorbackgroundinfo: 'wxcc/lwcolorbackgroundinfo',
      lwcolorfontwarning: 'wxcc/lwcolorfontwarning',
      lwcolorbackgroundwarning: 'wxcc/lwcolorbackgroundwarning',
      lwcolorfonturgent: 'wxcc/lwcolorfonturgent',
      lwcolorbackgroundurgent: 'wxcc/lwcolorbackgroundurgent',
      goscolorfontinfo: 'wxcc/goscolorfontinfo',
      goscolorbackgroundinfo: 'wxcc/goscolorbackgroundinfo',
      goscolorfontwarning: 'wxcc/goscolorfontwarning',
      goscolorbackgroundwarning: 'wxcc/goscolorbackgroundwarning',
      goscolorfonturgent: 'wxcc/goscolorfonturgent',
      goscolorbackgroundurgent: 'wxcc/goscolorbackgroundurgent',
      showQueueNameOnWrapup: 'wxcc/showQueueNameOnWrapup',
      showQueueNameOnTalking: 'wxcc/showQueueNameOnTalking'
    }),
    token () {
      return this.$store.state.auth.token
    },
    widgetsToShow () {
      return this.widgets.filter(el => {
        if (this.availableWidgets.includes(el.id) || el.id === 'gs-logo') return true
        return false
      })
    },
    cssProps () {
      return {
        '--font-color-info': this.colorfontinfo,
        '--background-color-info': this.colorbackgroundinfo,
        '--font-color-warning': this.colorfontwarning,
        '--background-color-warning': this.colorbackgroundwarning,
        '--font-color-urgent': this.colorfonturgent,
        '--background-color-urgent': this.colorbackgroundurgent,
        '--lw-font-color-info': this.lwcolorfontinfo,
        '--lw-background-color-info': this.lwcolorbackgroundinfo,
        '--lw-font-color-warning': this.lwcolorfontwarning,
        '--lw-background-color-warning': this.lwcolorbackgroundwarning,
        '--lw-font-color-urgent': this.lwcolorfonturgent,
        '--lw-background-color-urgent': this.lwcolorbackgroundurgent,
        '--gos-font-color-info': this.goscolorfontinfo,
        '--gos-background-color-info': this.goscolorbackgroundinfo,
        '--gos-font-color-warning': this.goscolorfontwarning,
        '--gos-background-color-warning': this.goscolorbackgroundwarning,
        '--gos-font-color-urgent': this.goscolorfonturgent,
        '--gos-background-color-urgent': this.goscolorbackgroundurgent
      }
    }
  },
  watch: {
    token (oldToken, newToken) {
      console.log('token changed, reconnecting')
      this.$store.dispatch('wxcc/STOP_WEBSOCKET')
      WebsocketService.connectWs(this.$store, this.dashId, this.updateAllStats, this.updateQueueStats, this.updateMarquee)
    }
  },
  components: {
    DashboardWidget,
    DashboardFooter
  },
  beforeDestroy () {
    if (this.poller) clearInterval(this.poller)
    this.$store.dispatch('wxcc/STOP_WEBSOCKET')
    this.$store.dispatch('wxcc/STOP_WORKER')
  },
  methods: {
    updateAllStats (delay) {
      console.log('updateAllStats', delay)
      const now = new Date().getTime()
      console.log(`updateAllStats now:${now}, lastWsMessage:${this.lastWsMessage}`)
      if (this.lastWsMessage < now - 5000 || this.lastStatsUpdate < now - 60000) {
        console.log('updateAllStats - process')
        setTimeout(() => { this.processAllStats() }, delay)
        // setTimeout(() => { this.getQueues() }, delay)
        // setTimeout(() => { this.getAgents() }, delay + 75)
        // setTimeout(() => { this.gethourlyQueueStats() }, delay + 150)
        // this.$store.commit('wxcc/setLastStatsUpdate', now)
      } else {
        console.log('updateAllStats - skip')
      }
    },
    processAllStats () {
      const now = new Date().getTime()
      if (this.lastWsMessage < now - 5000 || this.lastStatsUpdate < now - 60000) {
        this.getQueues()
        setTimeout(() => { this.getAgents() }, 75)
        setTimeout(() => { this.gethourlyQueueStats() }, 150)
        this.$store.commit('wxcc/setLastStatsUpdate', now)
      } else {
        console.log('updateAllStats - processAllStats - skip')
      }
    },
    updateQueueStats (delay) {
      console.log('updateQueueStats', delay)
      setTimeout(() => { this.getQueues() }, delay)
      setTimeout(() => { this.gethourlyQueueStats() }, delay + 75)
    },
    async updateMarquee () {
      const db = await GraphqlService.getDashboard(this.$store, this.dashId, false)
      this.$store.commit('wxcc/SET_DASHBOARD', db)
      this.availableWidgets = db.widgets
    },
    async getAgents () {
      console.log('getting agent stats')
      const agtOnHold = {}
      this.storedagents.forEach(agt => {
        if (agt.state === 'OnHold') {
          agtOnHold[agt.id] = agt.holdtime
        }
      })
      console.log('agents on hold', agtOnHold)
      const tmpagents = await GraphqlService.getAgents(this.$store, this.dashId)
      this.agents = tmpagents.map((agt, idx) => {
        const tempQueueName = agt.queuename
        agt.queuename = ''
        agt.displayName = `${agt.firstname} ${agt.lastname}`
        // if (this.showQueueNameOnTalking !== 'true' && this.showQueueNameOnWrapup !== 'true') agt.queuename = ''
        if (this.dbname.includes('Atea Test')) {
          if (idx < 50) {
            agt.displayName = this.dummyA[idx]
            agt.firstname = agt.displayName.split(' ')[0]
            agt.lastname = agt.displayName.split(' ')[1]
          } else {
            agt.displayName = `Dummy Agent ${idx}`
            agt.firstname = 'Dummy'
            agt.lastname = `Agent ${idx}`
          }
        }
        if (agt.state === 'idle') {
          agt.state = 'Not Ready'
          agt.stateCode = 3
        }
        if (agt.state === 'not-responding') {
          agt.state = 'Not Ready'
          agt.stateCode = 3
        }
        if (agt.state === 'available') {
          agt.state = 'Ready'
          agt.stateCode = 4
        }
        if (agt.state === 'offered') {
          agt.state = 'Ringing'
          agt.stateCode = 1
        }
        if (agt.state === 'ringing') {
          agt.state = 'Ringing'
          agt.stateCode = 1
        }
        if (agt.state === 'connected') {
          agt.state = 'Talking'
          agt.stateCode = 1
          if (this.showQueueNameOnTalking && this.showQueueNameOnTalking === 'true' && agt.queueid) {
            agt.queuename = tempQueueName
          }
        }
        if (agt.state === 'hold-done') {
          agt.state = 'Talking'
          agt.stateCode = 1
          if (this.showQueueNameOnTalking && this.showQueueNameOnTalking === 'true' && agt.queueid) {
            agt.queuename = tempQueueName
          }
        }
        if (agt.state === 'consulting') {
          agt.state = 'Talking'
          agt.stateCode = 1
          if (this.showQueueNameOnTalking && this.showQueueNameOnTalking === 'true' && agt.queueid) {
            agt.queuename = tempQueueName
          }
        }
        if (agt.state === 'consult-done') {
          agt.state = 'Talking'
          agt.stateCode = 1
          if (this.showQueueNameOnTalking && this.showQueueNameOnTalking === 'true' && agt.queueid) {
            agt.queuename = tempQueueName
          }
        }
        if (agt.state === 'on-hold') {
          agt.state = 'OnHold'
          agt.stateCode = 1
          if (agt.id in agtOnHold) agt.holdtime = agtOnHold[agt.id]
          else agt.holdtime = 0
        }
        if (agt.state === 'wrapup') {
          agt.state = 'Wrapup'
          agt.stateCode = 2
          if (this.showQueueNameOnWrapup && this.showQueueNameOnWrapup === 'true' && agt.queueid) {
            agt.queuename = tempQueueName
          }
        }
        if (agt.state === 'logged-out') {
          agt.state = 'Logout'
          agt.stateCode = 5
        }
        agt.stateAndIdleCode = `${agt.state} ${agt.reason}`
        agt.stateCodeAndIdleCode = `${agt.stateCode} ${agt.reason}`
        return agt
      })
      this.agents.sort((a, b) => {
        const result = a.firstname.localeCompare(b.firstname)
        return result !== 0 ? result : a.lastname.localeCompare(b.lastname)
      })
      this.$store.commit('wxcc/setAgents', this.agents)
    },
    async getQueues () {
      console.log('getting queue stats')
      this.queues = await GraphqlService.getQueues(this.$store, this.dashId)
      this.queues = this.queues.map((q, idx) => {
        if (this.dbname.includes('Atea Test')) {
          q.name = this.dummyQ[idx]
        }
        if (q.callswaiting > 0 && q.longestwaiting === 0) q.longestwaiting = 1
        q.taskstotal = Number(q.taskstotal)
        return q
      })
      this.$store.commit('wxcc/setQueues', this.queues)
    },
    async gethourlyQueueStats () {
      const hq = await GraphqlService.getHourlyQueues(this.$store, this.dashId)
      const hqstats = [['Hour', 'Handled', 'Abandoned']]
      const wstats = [['Hour', 'Max Wait', 'Avg Wait']]
      for (let i = 0; i < 24; i++) {
        hqstats.push([hq.labels[i], hq.handled[i], hq.abandoned[i]])
        wstats.push([hq.labels[i], hq.maxqueue[i], hq.avgqueue[i]])
        // wstats.push([hq.labels[i], new Date(0, 0, 0, 0, 0, hq.maxqueue[i]), new Date(0, 0, 0, 0, 0, hq.avgqueue[i])])
      }
      console.log('setHourlyQueues', hqstats)
      console.log('setHourlyWait', wstats)
      this.$store.commit('wxcc/setHourlyQueues', hqstats)
      this.$store.commit('wxcc/setHourlyWait', wstats)
    },
    saveGrid () {
      const gridSerial = {}
      document.querySelectorAll('.panel').forEach(element => {
        const gridCoords = {
          x: element.getAttribute('gs-x'),
          y: element.getAttribute('gs-y'),
          w: element.getAttribute('gs-w'),
          h: element.getAttribute('gs-h')
        }
        const id = element.getAttribute('gs-id')
        gridSerial[id] = gridCoords
      })
      console.log('saving grid', gridSerial)
      localStorage.setItem('atea-grid-layout-' + this.dashId, JSON.stringify(gridSerial))
      this.$refs.dashboardFooter.setLayoutSaved(true)
    },
    loadGrid () {
      if (localStorage.getItem('atea-grid-layout-' + this.dashId)) {
        const gLayout = JSON.parse(localStorage.getItem('atea-grid-layout-' + this.dashId))
        for (const [key, value] of Object.entries(gLayout)) {
          console.log('trying', key, value)
          const elem = document.querySelector(`#${key}`)
          if (elem) {
            console.log('found trying', elem)
            elem.setAttribute('gs-x', value.x)
            elem.setAttribute('gs-y', value.y)
            elem.setAttribute('gs-w', value.w)
            elem.setAttribute('gs-h', value.h)
            console.log('found trying', elem)
            console.log(elem.getAttribute('gs-y'))
          }
        }
      }
    }
  }
}
</script>
<style>
body {
  background: #272B30;
  height: 100vh;
  color: white;
  line-height: 1.2;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
</style>
<style>
#atea-widget-container {
  /* height: 100vh !important; */
}

#page-content-wrapper {
  height: 100vh;
  width: 100%;
  background: #272B30;
}
#widget-gs-logo {
  height: 100%;
  padding-bottom: 25px;
}
/* #widget-gs-queue {
  font-size: 6em;
} */
/* #longestWaiting{
  font-size: 6em;
}
#queues {
  font-size: 1.35em;
} */
</style>
