import axios from 'axios'

export default {
  getDashboards (store) {
    console.log('getDashboards', store.getters['wxcc/apiServer'])
    const gql = `query {
      dashboards {
        id
        name
        publicaccesstoken
        allowededitors
        allowedsupervisors
      }
    }`
    return this.graphQlQuery(gql, 'dashboards', store, false)
  },
  // getDevices (store) {
  //   const gql = `query {
  //     devices {
  //       displayName
  //       serial
  //       connectionStatus
  //       product
  //     }
  //   }`
  //   return this.graphQlQuery(gql, 'devices', store)
  // },
  // getPersons (store) {
  //   const gql = `query {
  //     persons {
  //       displayName
  //       nickName
  //       status
  //     }
  //   }`
  //   return this.graphQlQuery(gql, 'persons', store)
  // },
  getQueues (store, dashId) {
    // var offset = new Date().getTimezoneOffset()
    const gql = `query {
      queuestats (dashId: "${dashId}") {
        id
        name
        callswaiting
        longestwaiting
        gos
        tasksoffered
        taskshandled
        tasksrejected
        taskstotal
        tasksabandoned
        tasksshort
        avghandletime
        avgenqueuetime
        loggedinagents
        availableagents
        connectedagents
        routingtype
        channeltype
        maxwait
        avgasa
      }
    }`
    return this.graphQlQuery(gql, 'queuestats', store, false)
  },
  getHourlyQueues (store, dashId) {
    // var offset = new Date().getTimezoneOffset()
    const gql = `query {
      hourlyqueuestats (dashId: "${dashId}") {
        labels
        handled
        abandoned
        avgqueue
        maxqueue
      }
    }`
    return this.graphQlQuery(gql, 'hourlyqueuestats', store, false)
  },
  getAgents (store, dashId) {
    // var offset = new Date().getTimezoneOffset()
    const gql = `query {
      agentstats (dashId: "${dashId}") {
        id
        firstname
        lastname
        extension
        state
        reason
        duration
        tasksoffered
        taskshandled
        tasksrejected
        avghandletime
        queueid
        queuename
        teamname
        skillprofilename
      }
    }`
    return this.graphQlQuery(gql, 'agentstats', store, false)
  },
  getAlQueues (store) {
    const gql = `query {
      queues {
        id
        name
        channelType
        queueType
      }
    }`
    return this.graphQlQuery(gql, 'queues', store, false)
  },
  getQueueGroups (store, qgroups, isAdmin) {
    const gql = `query {
      queuegroups (qgroups: "${qgroups}") {
        id
        name
        queueids
      }
    }`
    return this.graphQlQuery(gql, 'queuegroups', store, isAdmin)
  },
  getDashboard (store, dashId, isAdmin) {
    const gql = `query {
      dashboard (dashId: "${dashId}") {
        id
        name
        queues
        widgets
        logo
        agentsort
        agentsort2
        agentsortdirection
        agentsortdirection2
        queuesort
        queuesortdirection
        emailsort
        emailsortdirection
        chatsort
        chatsortdirection
        hidenr
        message
        marqueetime
        marqueefontcolor
        queuetablecolumns
        emailtablecolumns
        chattablecolumns
        allowededitors
        allowedsupervisors
        showagentcallshandled
        showagentcallspresented
        showagentextension
        showagentteam
        showagentatt
        showagentskill
        gridlayout
        gridsizes
        groupids
        colorfontinfo
        colorbackgroundinfo
        colorfontwarning
        colorbackgroundwarning
        colorfonturgent
        colorbackgroundurgent
        lwcolorfontinfo
        lwcolorbackgroundinfo
        lwcolorfontwarning
        lwcolorbackgroundwarning
        lwcolorfonturgent
        lwcolorbackgroundurgent
        goscolorfontinfo
        goscolorbackgroundinfo
        goscolorfontwarning
        goscolorbackgroundwarning
        goscolorfonturgent
        goscolorbackgroundurgent
        queuenamealign
        showqueuenameonwrapup
        showqueuenameontalking
        cwvaluewarning
        cwvalueurgent 
        cwvalueinfo
        lwvaluewarning
        lwvalueurgent 
        lwvalueinfo
        gosvaluewarning
        gosvalueurgent
        gosvalueinfo
        nrcolors
        ateasupportfields
      }
    }`
    return this.graphQlQuery(gql, 'dashboard', store, isAdmin)
  },
  addDashboard (store) {
    const gql = `mutation {
      createdashboard (name: "changeme") {
        id
        name
      }
    }`
    return this.graphQlMutation(gql, 'createdashboard', store)
  },
  addQueueGroup (store) {
    const gql = `mutation {
      createqueuegroup (name: "changeme") {
        id
        name
      }
    }`
    return this.graphQlMutation(gql, 'createqueuegroup', store)
  },
  deleteQueueGroup (store, groupId) {
    const gql = `mutation {
      deletequeuegroup (groupId: "${groupId}") {
        id
      }
    }`
    return this.graphQlMutation(gql, 'deletequeuegroup', store)
  },
  deleteDashboard (store, dashId) {
    const gql = `mutation {
      deletedashboard (dashId: "${dashId}") {
        id
      }
    }`
    return this.graphQlMutation(gql, 'deletedashboard', store)
  },
  cloneDashboard (store, dashId) {
    const gql = `mutation {
      clonedashboard (dashId: "${dashId}") {
        id
      }
    }`
    return this.graphQlMutation(gql, 'clonedashboard', store)
  },
  saveDashboardName (store, dashId, dashboardName) {
    const gql = `mutation {
      savedashboardname (dashId: "${dashId}", name: "${dashboardName}") {
        id
        name
      }
    }`
    return this.graphQlMutation(gql, 'savedashboardname', store)
  },
  removeDashboardQueue (store, dashId, queueId) {
    const gql = `mutation {
      changequeueondashboard (dashId: "${dashId}", queueId: "${queueId}", action: "remove") {
        id
        name
      }
    }`
    return this.graphQlMutation(gql, 'changequeueondashboard', store)
  },
  addQueueToDashboard (store, dashId, queueId) {
    const gql = `mutation {
      changequeueondashboard (dashId: "${dashId}", queueId: "${queueId}", action: "add") {
        id
      }
    }`
    return this.graphQlMutation(gql, 'changequeueondashboard', store)
  },
  addQueueGroupToDashboard (store, dashId, queueGroupId) {
    const gql = `mutation {
      changequeuegroupondashboard (dashId: "${dashId}", queueGroupId: "${queueGroupId}", action: "add") {
        id
      }
    }`
    return this.graphQlMutation(gql, 'changequeuegroupondashboard', store)
  },
  removeQueueGroupFromDashboard (store, dashId, queueGroupId) {
    const gql = `mutation {
      changequeuegroupondashboard (dashId: "${dashId}", queueGroupId: "${queueGroupId}", action: "remove") {
        id
        name
      }
    }`
    return this.graphQlMutation(gql, 'changequeuegroupondashboard', store)
  },
  addQueueToQueueGroup (store, queueGroupId, queueId) {
    const gql = `mutation {
      changequeueonqueuegroup (queueGroupId: "${queueGroupId}", queueId: "${queueId}", action: "add") {
        id
      }
    }`
    return this.graphQlMutation(gql, 'changequeueonqueuegroup', store)
  },
  removeQueueFromQueueGroup (store, queueGroupId, queueId) {
    const gql = `mutation {
      changequeueonqueuegroup (queueGroupId: "${queueGroupId}", queueId: "${queueId}", action: "remove") {
        id
        name
      }
    }`
    return this.graphQlMutation(gql, 'changequeueonqueuegroup', store)
  },
  saveQueueGroupName (store, groupId, queueGroupName) {
    const gql = `mutation {
      savequeuegroupname (groupId: "${groupId}", name: "${queueGroupName}") {
        id
        name
      }
    }`
    return this.graphQlMutation(gql, 'savequeuegroupname', store)
  },
  syncDashboardConfig (store) {
    const gql = `mutation {
      syncdashboardconfig
    }`
    return this.graphQlMutation(gql, 'syncdashboardconfig', store)
  },
  saveDashboardLayout (store, dashId, layout) {
    const gql = `mutation {
      savedashboardlayout (dashId: "${dashId}",
        message: "${layout.message}",
        marqueetime: ${layout.marqueeTime},
        marqueefontcolor: "${layout.marqueeFontColor}",
        widgets: ["${layout.currentWidgets.join('","')}"],
        hidenr: ["${layout.hideNR.join('","')}"],
        logo: "${layout.logo}",
        queuesort: "${layout.queueSort}",
        queuesortdirection: "${layout.queueSortDirection}",
        emailsort: "${layout.emailSort}",
        emailsortdirection: "${layout.emailSortDirection}",
        chatsort: "${layout.chatSort}",
        chatsortdirection: "${layout.chatSortDirection}",
        agentsort: "${layout.agentSort}",
        agentsort2: "${layout.agentSort2}",
        agentsortdirection: "${layout.agentSortDirection}",
        agentsortdirection2: "${layout.agentSortDirection2}",
        queuetablecolumns: ["${layout.currentQueueColumns.join('","')}"]
        emailtablecolumns: ["${layout.currentEmailColumns.join('","')}"]
        chattablecolumns: ["${layout.currentChatColumns.join('","')}"]
        allowededitors: ["${layout.allowedEditors.join('","')}"]
        allowedsupervisors: ["${layout.allowedSupervisors.join('","')}"]
        showagentcallshandled: "${layout.showAgentCallsHandled}"
        showagentcallspresented: "${layout.showAgentCallsPresented}"
        showagentextension: "${layout.showAgentExtension}"
        showagentteam: "${layout.showAgentTeam}"
        showagentatt: "${layout.showAgentATT}"
        showagentskill: "${layout.showAgentSkill}"
        colorfontinfo: "${layout.colorfontinfo}"
        colorbackgroundinfo: "${layout.colorbackgroundinfo}"
        colorfontwarning: "${layout.colorfontwarning}"
        colorbackgroundwarning: "${layout.colorbackgroundwarning}"
        colorfonturgent: "${layout.colorfonturgent}"
        colorbackgroundurgent: "${layout.colorbackgroundurgent}"
        lwcolorfontinfo: "${layout.lwcolorfontinfo}"
        lwcolorbackgroundinfo: "${layout.lwcolorbackgroundinfo}"
        lwcolorfontwarning: "${layout.lwcolorfontwarning}"
        lwcolorbackgroundwarning: "${layout.lwcolorbackgroundwarning}"
        lwcolorfonturgent: "${layout.lwcolorfonturgent}"
        lwcolorbackgroundurgent: "${layout.lwcolorbackgroundurgent}"
        goscolorfontinfo: "${layout.goscolorfontinfo}"
        goscolorbackgroundinfo: "${layout.goscolorbackgroundinfo}"
        goscolorfontwarning: "${layout.goscolorfontwarning}"
        goscolorbackgroundwarning: "${layout.goscolorbackgroundwarning}"
        goscolorfonturgent: "${layout.goscolorfonturgent}"
        goscolorbackgroundurgent: "${layout.goscolorbackgroundurgent}"
        queuenamealign: "${layout.queuenamealign}"
        showqueuenameonwrapup: "${layout.showqueuenameonwrapup}"
        showqueuenameontalking: "${layout.showqueuenameontalking}"
        cwvaluewarning: ${layout.cwvaluewarning}
        cwvalueurgent: ${layout.cwvalueurgent}
        cwvalueinfo: ${layout.cwvalueinfo}
        lwvaluewarning: ${layout.lwvaluewarning}
        lwvalueurgent: ${layout.lwvalueurgent}
        lwvalueinfo: ${layout.lwvalueinfo}
        gosvaluewarning: ${layout.gosvaluewarning}
        gosvalueurgent: ${layout.gosvalueurgent}
        gosvalueinfo: ${layout.gosvalueinfo}
        nrcolors: ["${layout.nrcolors.join('","')}"]
        ateasupportfields: ["${layout.ateasupportfields.join('","')}"]
      ) {
        id
        name
      }
    }`
    return this.graphQlMutation(gql, 'savedashboardlayout', store)
  },
  saveDefaultLayout (store, dashID) {
    let gs = 'gridsizes: ""'
    if (localStorage.getItem('atea-grid-sizes-' + dashID)) {
      gs = `gridsizes: "${btoa(localStorage.getItem('atea-grid-sizes-' + dashID))}"`
    }
    const gql = `mutation {
      savedefaultlayout (dashId: "${dashID}",
        gridlayout: "${btoa(localStorage.getItem('atea-grid-layout-' + dashID))}",
        ${gs}
      ) {
        id
      }
    }`
    return this.graphQlMutation(gql, 'savedashboardlayout', store)
  },
  generateNewPublicAccessToken (store, dashID) {
    const gql = `mutation {
      generatenewpublicaccesstoken (dashId: "${dashID}") {
        id
      }
    }`
    return this.graphQlMutation(gql, 'generatenewpublicaccesstoken', store)
  },
  allowedPublic (data, query) {
    switch (data) {
      case 'dashboard':
        return true
      case 'queuegroups':
        if (query.includes('qgroups: "*"')) {
          return false
        }
        return true
      case 'queuestats':
        return true
      case 'agentstats':
        return true
      case 'hourlyqueuestats':
        return true
      default:
        return false
    }
  },
  graphQlQuery (query, data, store, isAdmin) {
    let gUrl = `/api/graphql/${store.getters['wxcc/wxccCustomerId']}`
    if (!isAdmin && (store.getters['wxcc/publicAccessToken'] !== '' && this.allowedPublic(data, query))) {
      gUrl = `/papi/graphql/${store.getters['wxcc/publicAccessToken']}`
    }
    return axios
      .post(
        gUrl,
        { query, operationName: '' }
        ,
        {
          // baseURL: process.env.VUE_APP_HEROKU_URL,
          baseURL: `${store.getters['wxcc/apiServer']}`,
          headers: {
            'WxCC-TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'WxCC-CustomerIds': store.getters['wxcc/wxccCustomerId'],
            Authorization: 'Bearer ' + localStorage.access_token,
            'Content-Type': 'application/json'
          }
        }
      ).then(res => {
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('errors')) {
          return { errors: res.data.errors }
        }
        return res.data.data[data]
      })
  },
  graphQlMutation (query, data, store) {
    return axios
      .post(
        `/api/graphql/${store.getters['wxcc/wxccCustomerId']}`,
        { query, operationName: '' }
        ,
        {
          // baseURL: process.env.VUE_APP_HEROKU_URL,
          baseURL: `${store.getters['wxcc/apiServer']}`,
          headers: {
            'WxCC-TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'WxCC-CustomerIds': store.getters['wxcc/wxccCustomerId'],
            Authorization: 'Bearer ' + localStorage.access_token,
            'Content-Type': 'application/json'
          }
        }
      ).then(res => {
        // eslint-disable-next-line no-prototype-builtins
        if (res.data.hasOwnProperty('errors')) {
          return { errors: res.data.errors }
        }
        return res.data.data[data]
      })
  }
}
