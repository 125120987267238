<template id="agentlist2">
<div id="agentslist" class="page-content-wrapper agentlistcontainer">
    <b-table
      small
      :items="sortedAgents"
      :fields.sync="visibleFields"
      no-local-sorting
      class="cy-table3 text-white"
    >
      <template #cell(icon)="row">
       <img class="agent agentListIcon" :src="getBase64Icon(row.item.state)" alt="agent icon"/>
      </template>
      <template #cell(displayName)="row">
       <span>{{row.item.firstname}} {{row.item.lastname}}</span>
      </template>
      <template #cell(state)="row">
       <span v-if="!row.item.reason || row.item.state !== 'Not Ready'">{{row.item.state}}<span v-if="row.item.state === 'OnHold'"> - {{ row.item.holdtime | formatSec }}</span></span>
       <span v-if="row.item.reason && row.item.state === 'Not Ready'">{{row.item.reason}}</span>
       <span v-if="showQueueNameOnWrapup && showQueueNameOnWrapup === 'true' && row.item.queuename && row.item.state === 'Wrapup'"> - {{row.item.queuename}}</span>
       <span v-if="showQueueNameOnTalking && showQueueNameOnTalking === 'true' && row.item.queuename && row.item.state === 'Talking'"> - {{row.item.queuename}}</span>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import AgentIconService from '../../service/AgentIconService'
// const agentSorter = function (r) { var n; var t = []; var u = r.length; return r = r.map(function (r, n) { return r[0] === '-' ? (t[n] = -1, r = r.substring(1)) : t[n] = 1, r }), function (e, f) { for (n = 0; n < u; n++) { var i = r[n]; if (e[i] > f[i]) return t[n]; if (e[i] < f[i]) return -t[n] } return 0 } }

export default {
  name: 'AgentsList',
  data () {
    return {
      fields: [
        {
          key: 'icon',
          visible: true,
          tdClass: 'iconCell'
        },
        {
          key: 'displayName',
          label: 'Name',
          visible: true
        },
        {
          key: 'state',
          label: 'State',
          tdClass: this.agentStateClass,
          tdAttr: this.nrAttr,
          visible: true
        },
        {
          key: 'teamname',
          label: 'Team',
          visible: false
        },
        {
          key: 'skillprofilename',
          label: 'Skill Profile',
          visible: false
        },
        {
          key: 'duration',
          label: 'Duration',
          formatter: this.formatSec,
          visible: true
        },
        {
          key: 'extension',
          label: 'Extension',
          visible: false
        },
        {
          key: 'tasksoffered',
          label: 'Calls Presented',
          visible: false
        },
        {
          key: 'taskshandled',
          label: 'Calls Handled',
          visible: true
        },
        {
          key: 'avghandletime',
          label: 'Avg Talk Time',
          formatter: this.formatSec,
          visible: false
        }
      ]
    }
  },
  props: {
    data: {
      type: Array
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.ateasupportfields !== null && this.ateasupportfields.length > 0) {
        for (const e of this.ateasupportfields) {
          if (e === 'agentlist.scroll') {
            const h = document.getElementById('agentslist').parentNode.parentNode.offsetHeight
            console.log(`agentlist height ${h}`)
            document.getElementById('agentslist').style.height = h + 'px'
            document.getElementById('agentslist').style.overflowY = 'scroll'
          }
        }
      }
    })
  },
  computed: {
    ...mapGetters({
      agents: 'wxcc/agents',
      agentSort: 'wxcc/agentSort',
      agentSort2: 'wxcc/agentSort2',
      agentSortDirection: 'wxcc/agentSortDirection',
      agentSortDirection2: 'wxcc/agentSortDirection2',
      showAgentCallsHandled: 'wxcc/showAgentCallsHandled',
      showAgentCallsPresented: 'wxcc/showAgentCallsPresented',
      showAgentExtension: 'wxcc/showAgentExtension',
      showAgentTeam: 'wxcc/showAgentTeam',
      showAgentATT: 'wxcc/showAgentATT',
      showAgentSkill: 'wxcc/showAgentSkill',
      showQueueNameOnWrapup: 'wxcc/showQueueNameOnWrapup',
      showQueueNameOnTalking: 'wxcc/showQueueNameOnTalking',
      hideNR: 'wxcc/hideNR',
      nrcolors: 'wxcc/nrcolors',
      ateasupportfields: 'wxcc/ateasupportfields'
    }),
    // agentSorter (r) {
    //   var n
    //   var t = []
    //   var u = r.length
    //   r = r.map(function (r, n) {
    //     return r[0] === '-' ? (t[n] = -1, r = r.substring(1)) : t[n] = 1, r
    //     }), function (e, f) { for (n = 0; n < u; n++) { var i = r[n]; if (e[i] > f[i]) return t[n]; if (e[i] < f[i]) return -t[n] } return 0 }
    //   return r
    // },
    sortedAgents () {
      let a = this.agents
      if (this.hideNR !== undefined && this.hideNR !== null && this.hideNR.length > 0 && this.hideNR[0] !== '') {
        a = a.filter(e => {
          if (this.hideNR.includes(e.reason)) return false
          return true
        })
      }
      // const sortConfig = []
      // if (this.agentSortDirection === 'asc') sortConfig.push(this.agentSort)
      // else sortConfig.push(`-${this.agentSort}`)
      // if (this.agentSort2 && this.agentSortDirection2) {
      //   if (this.agentSortDirection2 === 'asc') sortConfig.push(this.agentSort2)
      //   else sortConfig.push(`-${this.agentSort2}`)
      // }
      // return a.slice().sort(agentSorter(sortConfig))
      return a.slice().sort((o1, o2) => {
        if (this.agentSortDirection === 'asc') {
          if (o1[this.agentSort] < o2[this.agentSort]) return -1
          if (o1[this.agentSort] > o2[this.agentSort]) return 1
        } else {
          if (o1[this.agentSort] > o2[this.agentSort]) return -1
          if (o1[this.agentSort] < o2[this.agentSort]) return 1
        }
        if (this.agentSort2 && this.agentSortDirection2) {
          if (this.agentSortDirection2 === 'asc') {
            if (o1[this.agentSort2] < o2[this.agentSort2]) return -1
            if (o1[this.agentSort2] > o2[this.agentSort2]) return 1
          } else {
            if (o1[this.agentSort2] > o2[this.agentSort2]) return -1
            if (o1[this.agentSort2] < o2[this.agentSort2]) return 1
          }
        } else {
          if (`${o1.firstName} ${o1.lastName}` < `${o2.firstName} ${o2.lastName}`) return -1
          if (`${o1.firstName} ${o1.lastName}` > `${o2.firstName} ${o2.lastName}`) return 1
        }
        return 0
      })
      // return a
    },
    visibleFields () {
      const myfields = this.fields.map(e => {
        if (e.key === 'taskshandled') {
          e.visible = this.showAgentCallsHandled !== 'false'
        }
        if (e.key === 'tasksoffered') {
          e.visible = this.showAgentCallsPresented !== 'false' && this.showAgentCallsPresented !== '' && this.showAgentCallsPresented !== undefined
        }
        if (e.key === 'extension') {
          e.visible = this.showAgentExtension !== 'false' && this.showAgentExtension !== ''
        }
        if (e.key === 'teamname') {
          e.visible = this.showAgentTeam !== 'false' && this.showAgentTeam !== ''
        }
        if (e.key === 'avghandletime') {
          e.visible = this.showAgentATT !== 'false' && this.showAgentATT !== '' && this.showAgentATT !== undefined
        }
        if (e.key === 'skillprofilename') {
          e.visible = this.showAgentSkill !== 'false' && this.showAgentSkill !== '' && this.showAgentSkill !== undefined
        }
        return e
      })
      return myfields.filter(el => el.visible)
    }
  },
  methods: {
    getBase64Icon (presence) {
      switch (presence) {
        case 'Available':
          return AgentIconService.getBase64Icon('agent_green')
        case 'Away':
          return AgentIconService.getBase64Icon('agent_blue')
        case 'Wrapup':
          return AgentIconService.getBase64Icon('agent_wrapup')
        case 'Not Ready':
          return AgentIconService.getBase64Icon('agent_red')
        case 'Ringing':
          return AgentIconService.getBase64Icon('agent_redorange')
        case 'Talking':
          return AgentIconService.getBase64Icon('agent_orange')
        case 'OnHold':
          return AgentIconService.getBase64Icon('agent_orange')
        case 'Logout':
          return AgentIconService.getBase64Icon('agent_blue')
        default:
          return AgentIconService.getBase64Icon('agent_green')
      }
      // return AgentIconService.getBase64Icon(icon)
    },
    formatSec (sec) {
      if (sec >= 3600) return new Date(sec * 1000).toISOString().substring(11, 19)
      return new Date(sec * 1000).toISOString().substring(14, 19)
    },
    agentStateClass (value, key, item) {
      switch (value) {
        case 'Available':
          return 'atea-state-green text-black'
        case 'Away':
          return 'atea-state-red text-black'
        case 'Wrapup':
          return 'atea-state-blue text-black'
        case 'Not Ready':
          if (this.nrcolors !== null && this.nrcolors.length > 0) {
            for (const e of this.nrcolors) {
              const split = e.split(':')
              if (item.reason === split[0]) {
                return ''
              }
            }
          }
          return 'atea-state-red text-black'
        case 'Ringing':
          return 'atea-state-orange text-black'
        case 'Talking':
          return 'atea-state-orange text-black'
        case 'OnHold':
          return 'atea-state-orange text-black'
        case 'Logout':
          return 'atea-state-red text-black'
        default:
          return 'atea-state-green text-black'
      }
    },
    nrAttr (value, key, item) {
      if (value === 'Not Ready' && this.nrcolors !== null && this.nrcolors.length > 0) {
        for (const e of this.nrcolors) {
          const split = e.split(':')
          if (item.reason === split[0]) {
            if (split.length === 3) return { style: `background-color: ${split[1]}; color: ${split[2]};` }
            return { style: `background-color: ${split[1]}; color: black;` }
          }
        }
      }
    }
  },
  filters: {
    formatSec (sec) {
      if (sec >= 3600) return new Date(sec * 1000).toISOString().substring(11, 19)
      return new Date(sec * 1000).toISOString().substring(14, 19)
    }
  }
}
</script>
<style>
:root {
  --agent-list-icon-width: 20px;
}
.agentlistcontainer > table > thead > tr > th {
  background-color: #32383E !important;
  /* font-weight: normal; */
}
.agentlistcontainer > .b-table-sticky-header > table > thead > tr > th {
  background-color: #32383E !important;
  /* font-weight: normal; */
}
.agentlistcontainer {
  padding: 10px;
}
.Available {
  color: green;
}
.Wrapup {
  color: red;
}
.Ringing {
  color: orange;
}
.Talking {
  color: orange;
}
.atea-state-orange {
  background-color: orange;
  color: black;
}
.atea-state-green {
  background-color: green;
  color: black;
}
.atea-state-red {
  background-color: red;
  color: black;
}
.atea-state-blue {
  background-color: #4BBAEB;
  color: black;
}
.agentListIcon {
    border-radius: 5px;
    padding: 0px;
}
img.agentListIcon  {
    width: var(--agent-list-icon-width);
}
.iconCell {
  padding: 0 !important;
}
/* .agentIcon {
    width: 22px;
}

.agentIcon img {
    width: var(--agentIconHeight);
    height: var(--agentIconHeight);
} */
/* #agents  {
  padding: 10px;
} */
</style>
